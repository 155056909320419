<template lang="pug">
CanvasModal.login(
  isShow=true
  :extraClass="extraClass"
  @closeOverlay="closeModal"
  noPadding=true
  :hideCloseButton="!isCloseable"
  :maxWidth="500"
)
  .login__content
    .login__title Login into your account
    .login__text.t--center
      | Don't have an account?&nbsp;
      BaseButton.btn-go-register.btn--link(
        isTall=false
        isText=true
        @click="goToRegister"
      )
        span Create new
    .login__form
      BaseInputField(
        label="Email"
        v-model="email"
        isTall=true
        :isAlert="!isEmailValid"
        :msg="emailMsg"
        @keyup.enter="login"
        @input="isEmailValid = true"
      )
      BaseInputField(
        label="Password"
        v-model="pass"
        isTall=true
        type="password"
        :isAlert="!isPassValid"
        :msg="passMsg"
        @keyup.enter="login"
        @input="isPassValid = true"
      )
      .login__row-split
        BaseButton.btn-forgot-password.btn--link(
          isTall=false
          isText=true
          @click="goToForgotPassword"
        )
          span Forgot Password?
        BaseCheckbox(
          label="Remember Me"
          @change="rememberMe = !rememberMe"
        )
      BaseButton.btn-login(
        isFullWidth=true
        isPrimary=true
        isTall=true
        :disabled="isLoading"
        :isLoading="isLoading"
        @click="login"
      ) Log In
  .login__content.socials
    .bordered-sides
      span Or
    .login__social-buttons
      BaseButton.btn-register(
        isFullWidth=true
        isPrimary=true
        isTall=true
        iconBefore="icon-facebook"
        class="btn--facebook"
        :disabled="isLoading"
        :isLoading="isLoading"
        @click="authProvider('facebook')"
      )
      BaseButton.btn-register(
        isFullWidth=true
        isPrimary=true
        isTall=true
        iconBefore="icon-google"
        class="btn--google"
        :disabled="isLoading"
        :isLoading="isLoading"
        @click="authProvider('google')"
      )
</template>

<script>
import userAuthMixins from '@/components/mixins/user-auth-mixins';

export default {
  name: 'Login',
  mixins: [
    userAuthMixins,
  ],
  data() {
    return {
      isShaken: false,
      isEmailValid: true,
      isPassValid: true,
      email: '',
      pass: '',
      emailApiMsg: '',
      passApiMsg: '',
      rememberMe: false,
      isLoading: false,
      isCloseable: true,
    };
  },
  computed: {
    extraClass() {
      const cssClass = [];

      if (this.isShaken) cssClass.push('is-shaken');

      return cssClass.join(' ');
    },
  },
  methods: {
    closeModal() {
      if (!this.isCloseable) {
        this.isShaken = true;
        setTimeout(() => {
          this.isShaken = false;
        }, 500);
      } else {
        this.$emit('close-login');
      }
    },
  },
};
</script>

<style lang="scss">
.login {
  @include mobile() {
    align-items: flex-end;

    .canvas-modal__content {
      bottom: 0;
    }
  }
}

.login .content {
  text-align: left;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .login__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 75px;
    font-weight: 500;
    line-height: 1.4;

    @include mobile() {
      width: 100%;
      padding: 20px;
      display: block;
    };

    &:first-of-type {
      padding-bottom: 20px;
    }

    &:last-of-type {
      padding-top: 20px;
    }

    &.socials {
      position: relative;

      @include mobile() {
        padding-bottom: 20px;
      };
    }

    .login__title {
      font-size: 1.714em;
      font-weight: bold;
      line-height: 1.2;
      margin-bottom: 10px;
      text-align: center;
    }

    .login__text {
      color: $grey;
    }

    .login__form {
      margin-top: 20px;
      width: 100%;

      .login__row-split {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 0.875em;
        margin-top: 20px;
      }

      .btn-login {
        margin-top: 20px;
      }
    }
  }

  .bordered-sides {
    position: absolute;
    top: 0;
    left: 0;
  }

  .login__social-buttons {
    display: flex;
    justify-content: space-between;

    .btn-register {
      width: calc(50% - 10px);
      margin: 20px 0;

      i {
        margin: 0;
      }
    }
  }
}

.btn--link {
  padding: 0;
  font-size: unset;
  color: $btnPrimaryHollowText;
}
</style>
