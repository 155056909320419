import { mapMutations, mapState } from 'vuex';
import {
  isEmpty, isEmail,
} from '@/assets/scripts/utilities';
import helperMixins from '@/components/mixins/helper-mixins';
import { isProduction, iframeParentURL } from '@/assets/scripts/variables';

import UserApi from '@/services/api/UserApi';

const userAuthMixins = {
  mixins: [
    helperMixins,
  ],
  data() {
    return {
      isNameValid: true,
      isEmailValid: true,
      isPassValid: true,
      isConfirmPassValid: true,
      name: '',
      email: '',
      pass: '',
      confirmPass: '',
      nameApiMsg: '',
      emailApiMsg: '',
      passApiMsg: '',
      confirmPassApiMsg: '',
      hasSentEmail: false,
      isShaken: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapState([
      'signUpPage',
      'closeAuthPostCallback',
    ]),
    nameMsg() {
      let msg = this.nameApiMsg;
      if (isEmpty(this.name)) {
        msg = 'Please provide your name';
      }
      return msg;
    },
    emailMsg() {
      let msg = this.emailApiMsg;
      if (isEmpty(this.email)) {
        msg = 'Please provide your email address';
      } else if (!isEmail(this.email)) {
        msg = 'Please provide a valid email address';
      }
      return msg;
    },
    passMsg() {
      let msg = this.passApiMsg;
      if (isEmpty(this.pass)) {
        msg = this.isRegisterComponent ? 'Please choose your password' : 'Please provide your password';
      } else if (this.isRegisterComponent && this.pass !== this.confirmPass) {
        msg = 'Your chosen password does not match';
      }
      return msg;
    },
    confirmPassMsg() {
      let msg = this.confirmPassApiMsg;
      if (isEmpty(this.confirmPass)) {
        msg = 'Please confirm your password';
      } else if (this.isRegisterComponent && this.pass !== this.confirmPass) {
        msg = 'Your chosen password does not match';
      }
      return msg;
    },
    isRegisterComponent() {
      return this.$options.name === 'Register';
    },
    allLoginInputValid() {
      return this.isEmailValid && this.isPassValid;
    },
    allRegisterInputValid() {
      return this.isNameValid && this.isEmailValid && this.isPassValid && this.isConfirmPassValid;
    },
    allForgotPasswordInputValid() {
      return this.isEmailValid;
    },
  },
  methods: {
    ...mapMutations([
      'setShowLogin',
      'setShowRegister',
      'setShowForgotPassword',
      'setIsLoggedIn',
    ]),
    login() {
      this.isLoading = true;
      this.isEmailValid = !(isEmpty(this.email) || !isEmail(this.email));
      this.isPassValid = !isEmpty(this.pass);

      if (this.allLoginInputValid) {
        // Call login API
        UserApi.userLogin({
          email: this.email,
          password: this.pass,
          remember_me: this.rememberMe,
        }, 'v2')
          .then((response) => {
            this.authCallback(response);
            if (this.closeAuthPostCallback) this.isLoading = false;
          }).catch((error) => {
            console.error(error);
            if (error.response.data.message) {
              this.isEmailValid = false;
              this.isPassValid = false;
              this.emailApiMsg = error.response.data.message;
              this.passApiMsg = error.response.data.message;

              window.parent.postMessage(
                {
                  isLoggedIn: false,
                  message: error.response.data.message,
                },
                iframeParentURL(),
              );

              this.setIsLoggedIn(false);
              this.isLoading = false;
            }
          });
      } else {
        this.isLoading = false;
      }
    },
    register() {
      this.isLoading = true;
      this.isNameValid = !isEmpty(this.name);
      this.isEmailValid = !(isEmpty(this.email) || !isEmail(this.email));
      this.isPassValid = !(isEmpty(this.pass) || this.pass !== this.confirmPass);
      this.isConfirmPassValid = !(isEmpty(this.confirmPass) || this.pass !== this.confirmPass);

      if (this.allRegisterInputValid) {
        // Call registration API
        UserApi.userSignup({
          name: this.name,
          email: this.email,
          password: this.pass,
          password_confirmation: this.confirmPass,
          _signup_page: this.signUpPage,
        })
          .then((response) => {
            // Update tracking
            if (isProduction()) {
              if (typeof window.fbq !== 'undefined') {
                window.fbq('track', 'CompleteRegistration', { currency: 'USD', value: 1 });
              }
            }

            this.authCallback(response);
            if (this.closeAuthPostCallback) this.isLoading = false;
          }).catch((error) => {
            if (error.response.data.errors) {
              if (error.response.data.errors.name) {
                this.isNameValid = false;
                // eslint-disable-next-line
                this.nameApiMsg = error.response.data.errors.name[0];
              }

              if (error.response.data.errors.email) {
                this.isEmailValid = false;
                // eslint-disable-next-line
                this.emailApiMsg = error.response.data.errors.email[0];
              }

              if (error.response.data.errors.password) {
                this.isPassValid = false;
                this.isConfirmPassValid = false;
                // eslint-disable-next-line
                this.passApiMsg = error.response.data.errors.password[0];
                // eslint-disable-next-line
                this.confirmPassApiMsg = error.response.data.errors.password[0];
              }
            }

            window.parent.postMessage(
              {
                isLoggedIn: false,
              },
              iframeParentURL(),
            );

            this.setIsLoggedIn(false);
            this.isLoading = false;

            this.alertError('', 'Oops, something went wrong. Try again later.', 5000, 'TopCenterNotif');
          });
      } else {
        this.isLoading = false;
      }
    },
    authProvider(provider) {
      this.$auth.authenticate(provider)
        .then((response) => {
          this.socialLogin(provider, response);
        }).catch((err) => {
          console.log({ err });
        });
    },
    socialLogin(provider, response) {
      this.$http.post(`${process.env.VUE_APP_OFFEO_API}/v2/users/social-auth/${provider}/callback`, response)
        .then((authResponse) => {
          // console.log('socialLogin:authResponse', authResponse);
          this.authCallback(authResponse);
        })
        .catch((err) => {
          console.log({ err });

          this.setIsLoggedIn(false);

          this.alertError('', 'Oops, something went wrong. Try again later.', 5000, 'TopCenterNotif');
        });
    },
    resetPassword() {
      this.isLoading = true;
      this.isEmailValid = !(isEmpty(this.email) || !isEmail(this.email));

      if (this.allForgotPasswordInputValid) {
        // Call login API
        UserApi.userResetPassword({
          email: this.email,
        })
          .then(() => {
            this.hasSentEmail = true;
            if (this.closeAuthPostCallback) this.isLoading = false;
          }).catch((error) => {
            this.isEmailValid = false;
            this.emailApiMsg = error.response.data.message;
            this.isLoading = false;
          });
      } else {
        this.isLoading = false;
      }
    },
    goToLogin() {
      this.setShowLogin(true);
      this.setShowRegister(false);
      this.setShowForgotPassword(false);
      this.$emit('open-login');
    },
    goToRegister() {
      this.setShowLogin(false);
      this.setShowRegister(true);
      this.setShowForgotPassword(false);
      this.$emit('open-register');
    },
    goToForgotPassword() {
      this.setShowLogin(false);
      this.setShowRegister(false);
      this.setShowForgotPassword(true);
      this.$emit('open-forgot-password');
    },
    authCallback(response) {
      if (response.data.success) {
        // console.log('callback', response);
        const locationHref = new URL(window.location.href);
        const redirect = locationHref.searchParams.get('redirect') || '';

        if (redirect) {
          // has redirect parameter, should redirect after successful login
          setTimeout(() => {
            window.location.href = redirect;
          }, 250);
        }
        // for iframe
        window.parent.postMessage(
          {
            isLoggedIn: true,
            results: response.data.results,
          },
          iframeParentURL(),
        );

        if (this.closeAuthPostCallback) {
          this.closeAuth();
        }
      } else {
        this.alertError('', 'Oops, something went wrong. Try again later.', 5000, 'TopCenterNotif');
        window.parent.postMessage({ loginFail: true }, process.env.VUE_APP_PUBLIC_URL);
      }

      this.setIsLoggedIn(response.data.success);
    },
    closeAuth() {
      this.setShowLogin(false);
      this.setShowRegister(false);
      this.setShowForgotPassword(false);
    },
  },
};

export default userAuthMixins;
