<template lang="pug">
CanvasModal.register(
  isShow=true
  :extraClass="extraClass"
  @closeOverlay="closeModal"
  noPadding=true
  :hideCloseButton="!isCloseable"
)
  .register__content
    .register__title {{ headerText }}
    .register__text.t--center
      | Already got an account?&nbsp;
      BaseButton.btn-go-login(
        isTall=false
        isText=true
        @click="goToLogin"
      )
        span Log in here
    .register__form
      BaseInputField(
        label="Name"
        v-model="name"
        isTall=true
        :isAlert="!isNameValid"
        :msg="nameMsg"
        @keyup.enter="register"
        @input="isNameValid = true"
      )
      BaseInputField(
        label="Email"
        v-model="email"
        isTall=true
        :isAlert="!isEmailValid"
        :msg="emailMsg"
        @keyup.enter="register"
        @input="isEmailValid = true"
      )
      BaseInputField(
        label="Password"
        v-model="pass"
        isTall=true
        type="password"
        :isAlert="!isPassValid"
        :msg="passMsg"
        @keyup.enter="register"
        @input="isPassValid = true"
      )
      BaseInputField(
        label="Confirm password"
        v-model="confirmPass"
        isTall=true
        type="password"
        :isAlert="!isConfirmPassValid"
        :msg="confirmPassMsg"
        @keyup.enter="register"
        @input="isConfirmPassValid = true"
      )
      span.smaller
        | By signing up, I agree to OFFEO's&nbsp;
        a(:href="termsUrl" target="_blank") Terms & Conditions&nbsp;
        | and&nbsp;
        a(:href="privacyUrl" target="_blank") Privacy Policy.
      BaseButton.btn-register(
        isFullWidth=true
        isPrimary=true
        isTall=true
        :disabled="isLoading"
        :isLoading="isLoading"
        @click="register"
      ) Sign Up

      .bordered-sides
        span Or
      .register__social-buttons
        BaseButton.btn-register(
          isFullWidth=true
          isPrimary=true
          isTall=true
          iconBefore="icon-facebook"
          class="btn--facebook"
          :disabled="isLoading"
          :isLoading="isLoading"
          @click="authProvider('facebook')"
        )
        BaseButton.btn-register(
          isFullWidth=true
          isPrimary=true
          isTall=true
          iconBefore="icon-google"
          class="btn--google"
          :disabled="isLoading"
          :isLoading="isLoading"
          @click="authProvider('google')"
        )
  .register__content.example
    .example__wrapper
      img(
        class="example__background"
        src="@/assets/images/backgrounds/premium-example.png"
      )
      template(v-if="!emptySelectedInspirationItem")
        InspirationListItem.example__gif(
          :index="selectedInspirationListItem.index"
          :id="selectedInspirationListItem.id"
          :templateId="selectedInspirationListItem.templateId"
          :width="selectedInspirationListItem.width"
          :height="selectedInspirationListItem.height"
          :duration="selectedInspirationListItem.duration"
          :background="selectedInspirationListItem.background"
          :previews="selectedInspirationListItem.previews"
          :hero="selectedInspirationListItem.hero"
          :isPreview="true"
        )
      template(v-else)
        img(
          class="example__gif"
          src="@/assets/images/backgrounds/premium-example.gif"
        )
</template>

<script>
import { mapState } from 'vuex';
import { isEmptyObject } from '@/assets/scripts/utilities';
import { homeUrl } from '@/assets/scripts/variables';
import userAuthMixins from '@/components/mixins/user-auth-mixins';


import InspirationListItem from '@/components/SmartCreate/children/InspirationListItem.vue';

export default {
  name: 'Register',
  components: {
    InspirationListItem,
  },
  mixins: [
    userAuthMixins,
  ],
  props: {
    headerText: { type: String, default: 'Get started with OFFEO' },
  },
  data() {
    return {
      termsUrl: `${homeUrl()}/terms-conditions`,
      privacyUrl: `${homeUrl()}/privacy-policy`,
      isShaken: false,
      isLoading: false,
      isCloseable: true,
    };
  },
  computed: {
    ...mapState('inspirationList', [
      'selectedInspirationListItem',
    ]),
    extraClass() {
      const cssClass = [];

      if (this.isShaken) cssClass.push('is-shaken');

      return cssClass.join(' ');
    },
    emptySelectedInspirationItem() {
      return isEmptyObject(this.selectedInspirationListItem);
    },
  },
  methods: {
    closeModal() {
      if (!this.isCloseable) {
        this.isShaken = true;
        setTimeout(() => {
          this.isShaken = false;
        }, 500);
      } else {
        this.$emit('close-register');
      }
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.register {
  @include mobile() {
    align-items: flex-end;

    .canvas-modal__content {
      bottom: 0;
    }
  }
}

.register .content {
  text-align: left;
  display: flex;
  justify-content: space-between;

  .register__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    padding: 75px;
    font-weight: 500;
    line-height: 1.4;

    @include mobile() {
      width: 100%;
      padding: 20px;
      display: block;
    };

    &:first-child {
      border-right: 1px solid $borderGrey;
    }

    &.example {
      width: 60%;
      box-shadow: none;
      background: url('~@/assets/images/backgrounds/premium-modal.png');
      background-size: cover;
      background-position: right;
      padding: 0;

      @include mobile() {
        display: none;
      };

      .btn-logout {
        position: absolute;
        top: 10px;
        right: 20px;
        z-index: 2;
        color: $red;
        text-decoration: underline;
      }

      .example__wrapper {
        position: relative;
        top: 30px;
        width: 100%;
        height: 540px;

        .example__background {
          position: absolute;
          height: 540px;
          z-index: 10;
        }

        .example__gif {
          position: absolute;
          width: 165px;
          left: 240px;
          top: 105px;
          margin: 0;
        }
      }
    }

    .register__title {
      font-size: 1.438em;
      font-weight: bold;
      line-height: 1.2;
      margin-bottom: 10px;
      text-align: center;
    }

    .register__text {
      color: $grey;

      .btn-go-login {
        padding: 0;
        font-size: unset;
        color: $btnPrimaryHollowText;
      }
    }

    .register__form {
      margin-top: 20px;

      .input-field__wrapper {
        &:last-of-type {
          margin-bottom: 20px;
        }
      }

      .btn-register {
        margin: 20px 0;
      }

      .register__social-buttons {
        display: flex;
        justify-content: space-between;

        @include mobile() {
          padding-bottom: 20px;
        }

        .btn-register {
          width: calc(50% - 10px);

          i {
            margin: 0;
          }
        }
      }
    }

    .smaller {
      font-size: 0.857em;
    }
  }
}

.bordered-sides {
  position: relative;
  width: 100%;
  text-align: center;

  span {
    color: $textGrey;
    padding: 0 15px;
    background: $lightBg;
  }

  &::after {
    content: "";
    width: 100%;
    height: 1px;
    background-color: $borderGrey;
    position: absolute;
    left: 0;
    top: calc(100% / 2);
    z-index: -1;
  }
}

.t--center {
  text-align: center;
}
</style>
