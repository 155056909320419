<template lang="pug">
CanvasModal.forgot-password(
  isShow=true
  :extraClass="extraClass"
  @closeOverlay="closeModal"
  noPadding=true
  :hideCloseButton="!isCloseable"
  :maxWidth="500"
)
  .forgot-password__content
    template(v-if="hasSentEmail")
      .forgot-password__title
        i.icon.icon-tick
      .forgot-password__title Reset password sent
      .forgot-password__text.t--center
        | Please check your email inbox, including your spambox. Please remember to change your password after you login.
      BaseButton.btn-login(
        isFullWidth=true
        isPrimary=true
        isTall=true
        @click="goToLogin"
      ) Log In
    template(v-else)
      .forgot-password__title Reset Password
      .forgot-password__text.t--center
        | A reset password will be sent to the email account that is registered with us.&nbsp;
        br
        | Already got an account?&nbsp;
        BaseButton.btn-go-login.btn--link(
          isTall=false
          isText=true
          @click="goToLogin"
        )
          span Log in here
      .forgot-password__form
        BaseInputField(
          label="Email"
          v-model="email"
          isTall=true
          :isAlert="!isEmailValid"
          :msg="emailMsg"
          @input="isEmailValid = true"
          @keyup.enter="resetPassword"
        )
        BaseButton.btn-reset-password(
          isFullWidth=true
          isPrimary=true
          isTall=true
          :disabled="isLoading"
          :isLoading="isLoading"
          @click="resetPassword"
        ) Reset Password
</template>

<script>
import userAuthMixins from '@/components/mixins/user-auth-mixins';

export default {
  name: 'ForgotPassword',
  mixins: [
    userAuthMixins,
  ],
  data() {
    return {
      isCloseable: true,
    };
  },
  computed: {
    extraClass() {
      const cssClass = [];

      if (this.isShaken) cssClass.push('is-shaken');

      return cssClass.join(' ');
    },
  },
  methods: {
    closeModal() {
      if (!this.isCloseable) {
        this.isShaken = true;
        setTimeout(() => {
          this.isShaken = false;
        }, 500);
      } else {
        this.$emit('close-forgot-password');
      }
    },
  },
};
</script>

<style lang="scss">
.forgot-password  {
  @include mobile() {
    align-items: flex-end;
  }

  .content {
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .forgot-password__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding: 75px;
      font-weight: 500;
      line-height: 1.4;

      .forgot-password__title {
        font-size: 1.714em;
        font-weight: bold;
        line-height: 1.2;
        margin-bottom: 10px;
        text-align: center;

        .icon-tick {
          color: $lightGreen;
          font-size: 2.5em;
          line-height: 0;
        }
      }

      .forgot-password__text {
        color: $grey;
      }

      .forgot-password__form {
        margin-top: 20px;
        width: 100%;
      }

      .btn-login,
      .btn-reset-password {
        margin-top: 20px;
      }
    }

    .bordered-sides {
      position: absolute;
    }
  }
}

.btn--link {
  padding: 0;
  font-size: unset;
  color: $btnPrimaryHollowText;
}
</style>
